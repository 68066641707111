@use '@angular/material' as mat;

@include mat.core();
@include mat.legacy-core();

$brand-color: (
        50 : #E2E5E8,
        100 : #B6BEC6,
        200 : #8592A0,
        300 : #546679,
        400 : #2F465D,
        500 : #04abe4,
        600 : #09213A,
        700 : #071B32,
        800 : #05162A,
        900 : #030D1C,
        A100 : #5A8EFF,
        A200 : #276BFF,
        A400 : #004CF3,
        A700 : #0044DA,
        contrast: (
                50 : #000000,
                100 : #000000,
                200 : #000000,
                300 : #000000,
                400 : #ffffff,
                500 : #ffffff,
                600 : #ffffff,
                700 : #ffffff,
                800 : #ffffff,
                900 : #ffffff,
                A100 : #000000,
                A200 : #000000,
                A400 : #000000,
                A700 : #ffffff,
        )
);

$brand-color-secondary: (
  50 : #e1e7e8,
  100 : #b4c3c5,
  200 : #829b9e,
  300 : #4f7377,
  400 : #2a555a,
  500 : #0A2540,
  600 : #033137,
  700 : #032a2f,
  800 : #022327,
  900 : #01161a,
  A100 : #58dbff,
  A200 : #25d0ff,
  A400 : #00bef1,
  A700 : #00aad8,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #ffffff,
    400 : #ffffff,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #000000,
    A200 : #000000,
    A400 : #000000,
    A700 : #ffffff,
  )
);

$brand-color-client: (
        50 : #e5ecf5,
        100 : #bed1e7,
        200 : #93b2d7,
        300 : #6893c6,
        400 : #477bba,
        500 : #2764ae,
        600 : #235ca7,
        700 : #1d529d,
        800 : #174894,
        900 : #0e3684,
        A100 : #b5cbff,
        A200 : #82a8ff,
        A400 : #4f84ff,
        A700 : #3672ff,
        contrast: (
                50 : #000000,
                100 : #000000,
                200 : #000000,
                300 : #000000,
                400 : #ffffff,
                500 : #ffffff,
                600 : #ffffff,
                700 : #ffffff,
                800 : #ffffff,
                900 : #ffffff,
                A100 : #000000,
                A200 : #000000,
                A400 : #000000,
                A700 : #ffffff,
        )
);

// Available color palettes: https://material.io/design/color/
$primary-palette: mat.define-palette($brand-color);
$accent-palette: mat.define-palette($brand-color-secondary);
$warn-palette: mat.define-palette(mat.$red-palette);

$primary-pallete-client: mat.define-palette($brand-color-client);

$light-theme: mat.define-light-theme((
  color: (
    primary: $primary-palette,
    accent: $accent-palette,
    warn: $warn-palette
  ),
  typography: mat.define-typography-config(),
  density: 0,
));
$light-theme: map_merge($light-theme, (custom: $primary-pallete-client));
$custom: map-get($light-theme, custom);

// Emit theme-dependent styles for common features used across multiple components.
//@include mat.core-theme($light-theme);
@include mat.all-component-themes($light-theme);
@include mat.all-legacy-component-themes($light-theme);
//@include mat.all-legacy-component-typographies($light-theme);

a {
  color: mat.get-color-from-palette($primary-palette);
  text-decoration: none !important;
}

a:hover {
  color: mat.get-color-from-palette($primary-palette, 300);
}

.primary-color-text {
  color: mat.get-color-from-palette($primary-palette);
}

.dropdown-item:hover, .dropdown-item:focus {
  color: mat.get-color-from-palette($primary-palette, 300);
}

.navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
  color: mat.get-color-from-palette($primary-palette, 300);
}

.navbar-dark .navbar-nav .active > .nav-link {
  color: mat.get-color-from-palette($primary-palette, 500);
}

.form-control:focus {
  border-color: mat.get-color-from-palette($primary-palette) !important;
}

.has-length .input-group-text {
  border-color: mat.get-color-from-palette($primary-palette) !important;
}

th.bg-primary {
  background-color: mat.get-color-from-palette($primary-palette) !important;
}

.text-primary {
  color: mat.get-color-from-palette($primary-palette) !important;
}

.border-primary {
  border-color: mat.get-color-from-palette($primary-palette) !important;
}

.uploaded-file-name {
  color: mat.get-color-from-palette($primary-palette);
  text-decoration: underline;
}

.navigation-bar {
  background-color: mat.get-color-from-palette($accent-palette);
}

.nav-link:hover {
  color: mat.get-color-from-palette($primary-palette, 300);
}

.mat-row:hover > td a:hover {
  color: mat.get-color-from-palette($primary-palette, 200);
}

div.bg-primary {
  background-color: mat.get-color-from-palette($primary-palette) !important;
}

span.badge-primary {
  background-color: mat.get-color-from-palette($accent-palette);
}

.custom-file-input:focus ~ .custom-file-label {
  border-color: mat.get-color-from-palette($primary-palette) !important;
}

.outcome-wrap .card-header.selected {
  background: mat.get-color-from-palette($primary-palette) !important;
}

.swal2-confirm {
  background: mat.get-color-from-palette($primary-palette) !important;
}

.e-checkbox-wrapper .e-frame.e-check, .e-css.e-checkbox-wrapper .e-frame.e-check {
  background-color: mat.get-color-from-palette($primary-palette);
}

.e-checkbox-wrapper:hover .e-frame.e-check, .e-css.e-checkbox-wrapper:hover .e-frame.e-check {
  background-color: mat.get-color-from-palette($primary-palette);
}

.e-treeview .e-list-item.e-active > .e-text-content .e-list-text {
  color: mat.get-color-from-palette($primary-palette);
}

.e-treeview .e-list-item.e-active.e-hover > .e-text-content .e-list-text {
  color: mat.get-color-from-palette($primary-palette);
}

.card-radio-button.mat-radio-checked .mat-radio-label {
  border-color: mat.get-color-from-palette($primary-palette);
}

.progress-bar.progress-bar-striped {
  background-color: mat.get-color-from-palette($primary-palette);
}

.calendar-view-wrap .btn-primary {
  background-color: mat.get-color-from-palette($primary-palette) !important;
  border-color: mat.get-color-from-palette($primary-palette) !important;
}

.calendar-view-wrap .btn-primary.active {
  background-color: mat.get-color-from-palette($accent-palette) !important;
  border-color: mat.get-color-from-palette($accent-palette) !important;
}

.mat-step-header .mat-step-icon-selected, .mat-step-header .mat-step-icon-state-done, .mat-step-header .mat-step-icon-state-edit {
  background-color: mat.get-color-from-palette($custom, 500); // Testing new custom theme
}

.client-button {
  background-color: mat.get-color-from-palette($custom, 500) !important; // Testing new custom theme
  &:disabled {
    color: rgba(0, 0, 0, 0.26) !important;
    background-color: rgba(0, 0, 0, 0.12) !important;
  }
}

.client-button-empty {
  color: mat.get-color-from-palette($custom, 500) !important; // Testing new custom theme

  .mat-button-focus-overlay {
    background-color: mat.get-color-from-palette($custom, 500) !important; // Testing new custom theme
  }

  &:disabled {
    color: white !important;
  }
}
