/* You can add global styles to this file, and also import other style files */
@import "styles/material-theme";
@import "../../_base-shared/styles/base-shared-styles";


.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgb(80 80 80 / 11%) !important;
}

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;

  .mat-table {
    width: 100%;
    max-width: 100%;
    margin-bottom: 1rem;
    display: table;
    border-collapse: collapse;
    margin: 0px;
  }

  .mat-row,
  .mat-header-row {
    display: table-row;
  }

  .mat-cell,
  .mat-header-cell,
  .mat-footer-cell {
    word-wrap: initial;
    display: table-cell;
    padding: 0px 5px;
    line-break: unset;
    width: auto;
    white-space: nowrap;
    overflow: hidden;
    vertical-align: middle;
  }
}

.cdk-overlay-container .dropdown-custom {
  margin-top: 23px;
}

.cdk-overlay-container .dropdown-custom-two {
  margin-top: 8px;
}

.notificationsMenu .mat-menu-content:not(:empty){
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.userMenu .mat-menu-content:not(:empty){
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.dropdown-header{
  width: 280px;
}

.mat-menu-content:not(:empty){
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.cdk-overlay-pane .selectAllMenu{
  position: absolute !important;
  top: -41px;
  right: 115px;
}

.selectAllMenu {
  .mat-menu-item{
    font-size: 13px !important;
  }
}

.cdk-overlay-pane .sendContractMenu{
  position: absolute !important;
  top: -38px;
  left: 165px;
}

.cdk-overlay-pane .resignContractMenu{
  position: absolute !important;
  top: -38px;
  left: -145px;
}

.resignContractMenu {
  .mat-menu-item{
    font-size: 13px !important;
  }
}

.cdk-overlay-pane .eSignMenu{
  position: absolute !important;
  top: -36px;
  right: 244px;
}

.eSignMenu {
  .mat-menu-item{
    font-size: 13px !important;
  }
}


.cdk-overlay-pane .sendSepaLinkMenu{
  position: absolute !important;
  top: -37px;
  left: 171px;
}

.cdk-overlay-pane .sendMenu{
  position: absolute !important;
  top: 324px;
  left: -125px;
}

.cdk-overlay-pane .saveDocMenu{
  position: absolute !important;
  top: -36px !important;
  left: -161px !important;
}

.saveDocMenu .mat-menu-item{
  font-size: 13px !important;
  width: 160px !important;
  padding-left: 24px !important;
}

.login-background{
  margin: auto;
  width: 100%;
  height: 100vh;
  background-image: url('/assets/img/welcome_to_unifye.png');
  background-repeat:no-repeat;
  background-position: center center;
  background-size: 100%;
  overflow: hidden;
}

@media only screen and (max-width: 900px) {
  .login-background {
    background-size: cover;
  }
}
